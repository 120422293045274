import { AuthProvider } from "../shared/context/auth"
import { I18nextProvider } from "react-i18next"
import IsApp from "../components/miscellaneous/is-app"
import { LoadingProvider } from "../shared/context/loading"
import { LocalesProvider } from "../shared/context/locales"
import { OverlayProvider } from "../shared/context/overlay"
import { PopperProvider } from "../shared/context/popper"
import React from "react"
import i18next from "../i18n/config"

const wrapPage = ({ element, props }) => {
    if (i18next.language !== props.pageContext.language) {
        i18next.changeLanguage(props.pageContext.language)
    }

    return (
        <I18nextProvider i18n={i18next}>
            <LocalesProvider
                language={props.pageContext.language}
                region={props.pageContext.region}
                slug={props.pageContext.slug}
            >
                <OverlayProvider>
                    <PopperProvider>
                        <LoadingProvider>
                            <AuthProvider>
                                <IsApp />
                                {element}
                            </AuthProvider>
                        </LoadingProvider>
                    </PopperProvider>
                </OverlayProvider>
            </LocalesProvider>
        </I18nextProvider>
    )
}

export default wrapPage
