import i18next from "i18next"
import { initReactI18next } from "react-i18next"

const defaultResources = {
    de: {
        translations: require("../locales/de/translations.json"),
        shared: require("../shared/locales/de/shared.json"),
    },
    en: {
        translations: require("../locales/en/translations.json"),
        shared: require("../shared/locales/en/shared.json"),
    },
}

const swissResources = {
    de: {
        translations: {...require("../locales/de/translations.json"), ...require("../locales/de-CH/translations.json")},
        shared: {...require("../shared/locales/de/shared.json"), ...require("../shared/locales/de-CH/shared.json")},
    },
    en: {
        translations: {...require("../locales/en/translations.json"), ...require("../locales/en-CH/translations.json")},
        shared:{...require("../shared/locales/en/shared.json"), ...require("../shared/locales/en-CH/shared.json")},
    },
}

const resources = process.env.REGION === "CH" ? swissResources : defaultResources

i18next.use(initReactI18next).init({
    resources: resources,
    lng: process.env.DEFAULT_LANGUAGE,
    ns: ["translations"],
    defaultNS: "translations",
    returnObjects: true,
    debug: process.env.NODE_ENV === "development",
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: true,
    },
})

export default i18next
