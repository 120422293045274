import "./src/shared/styles/icon-font.css"
import "./src/shared/styles/toast.css"
import "./src/shared/styles/reset.css"
import "./src/styles/global.css"
import "./src/styles/font.css"

import { getCookie, setCookie } from "./src/shared/utils/cookie"

import Tracker from "@openreplay/tracker/cjs"
import client from "./src/shared/utils/client"
import { languages } from "./src/shared/config/languages"
import { loadAsyncScript } from "./src/shared/utils/load-async-script"
import trackerAssist from "@openreplay/tracker-assist"
import { waitFor } from "./src/shared/utils/wait-for"
import wrapPage from "./src/gatsby-api/wrap-page"
import wrapRoot from "./src/gatsby-api/wrap-root"

// can be removed as soon as we drop safari < 12 support
import "intersection-observer" // polyfill for IntersectionObserver

/* global exponea */
/* global trustbadge */

const onRouteUpdate = ({ location, prevLocation }) => {
    if (!prevLocation && !location.hash) {
        // do not restore scroll position on page refresh without hash
        setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: "instant" }), 0)
    }

    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
        exponea.track("page_visit", {
            location: location.href,
            path: location.pathname,
        })
    }, 50)

    if (process.env.TRUSTED_SHOPS_ID && typeof trustbadge !== "undefined") {
        loadAsyncScript(`https://widgets.trustedshops.com/js/${process.env.TRUSTED_SHOPS_ID}.js`)
    }
}

const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    window.history.scrollRestoration = "manual"
    const currentPosition = getSavedScrollPosition(location) || [0, 0]

    setTimeout(() => {
        if (location.hash) {
            // If hash (`/#something`), smooth scroll to that position
            const item = document.querySelector(`${location.hash}`)
            item?.scrollIntoView({ behavior: "smooth" })
        } else {
            // when using the browser back/forward buttons or navigating to a different page client-side, instant scroll to its last saved position
            window.scrollTo({
                top: currentPosition[1],
                left: currentPosition[0],
                behavior: "instant",
            })
        }
    }, 0)

    // do not let gatsby do anything more
    return false
}

const onClientEntry = () => {
    const pathArray = window.location.pathname.split("/")
    const language =
        languages.find(l => l.langCode === pathArray[1])?.langCode ||
        getCookie("__language__") ||
        process.env.DEFAULT_LANGUAGE
    setCookie("__language__", language)

    if (typeof window.tracker === "undefined" && process.env.NODE_ENV !== "development") {
        window.tracker = new Tracker({
            projectKey: process.env.OPENREPLAY_KEY,
            ingestPoint: process.env.OPENREPLAY_URL,
            obscureTextEmails: false,
            obscureInputEmails: false,
            captureIFrames: false,
            __DISABLE_SECURE_MODE: window.location.hostname === "localhost",
        })
        window.tracker.use(trackerAssist({}))
        window.tracker.start()
    }

    const track = () => {
        const params = new URLSearchParams(window.location.search)

        let body = {
            exponea_id: getCookie("__exponea_etc__"),
        }
        if (document.referrer && !document.referrer.includes(process.env.HOSTNAME)) {
            body.referrer = document.referrer
        }
        if (params.get("utm_source")) {
            body.utm_source = params.get("utm_source")
        }
        if (params.get("utm_medium")) {
            body.utm_medium = params.get("utm_medium")
        }
        if (params.get("utm_campaign")) {
            body.utm_campaign = params.get("utm_campaign")
        }
        if (params.get("gclid")) {
            body.gclid = params.get("gclid")
        }
        if (params.get("__lx")) {
            body.__lx = params.get("__lx")
        }
        if (Object.keys(body).length > 1 && body.exponea_id) {
            try {
                client.createCustomerTracking(body)
            } catch (error) {
                return
            }
        }
    }

    window.addEventListener("exponea:load", () => waitFor(() => !!getCookie("__exponea_etc__"), track), { once: true })
}

const wrapRootElement = wrapRoot
const wrapPageElement = wrapPage

export { onRouteUpdate, shouldUpdateScroll, wrapRootElement, wrapPageElement, onClientEntry }
