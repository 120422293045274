import { useEffect } from "react"

const IsApp = () => {
    useEffect(() => {
        if (!/lavapp/.test(window.navigator.userAgent)) return

        window.location.href = "lavitaapp://navigate/shop"
    }, [])

    return null
}

export default IsApp
